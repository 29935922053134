const adbutlerZones = (page) => {
  switch (page) {
    case "solar":
      return ["477497", "477498", "477499", "477500", "477501"];
    case "windows":
      return ["477497", "477498", "477499", "477500", "477501"];
    case "roofing":
      return ["477497", "477498", "477499", "477500", "477501"];
    case "auto":
      return ["477636", "477637", "477638", "477639", "477640"];
    case "home":
      return ["477641", "477642", "477643", "477644", "477645"];
    case "life":
      return ["477646", "477647", "477648", "477649", "477650"];
    case "medsup":
      return ["477651", "477652", "477653", "477654", "477655"];
    case "health":
      return ["477656", "477657", "477658", "477659", "477660"];
    case "purchase":
      return ["477428", "477429", "477430", "477431", "477432"];
    case "dpa":
      return ["477428", "477429", "477430", "477431", "477432"];
    case "rto":
      return ["477428", "477429", "477430", "477431", "477432"];
    case "refinance":
      return ["477422", "477423", "477424", "477425", "477426"];

    default:
      return ["477422", "477423", "477424", "477425", "477426"];
  }
};

export default adbutlerZones;
