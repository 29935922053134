import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InnerHTML from "dangerously-set-html-content";

const AdButlerContainer = styled(InnerHTML)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 40px;

  br {
    margin-bottom: 0;
  }
  iframe {
    height: auto;
    width: 75%;
  }

  iframe:not([id="placement_274577_0_iframe"]) {
    width: 100vw;
    max-width: 900px;
  }
  @media (max-width: 1100px) {
    iframe,
    iframe:not([id="placement_274577_0_iframe"]) {
      width: 100%;
    }
  }
`;

const AdButler = ({ adButlerIds }) => {
  const [html, setHtml] = useState("");

  useEffect(() => {
    const findIframes = document && document.getElementsByTagName("iframe");

    const removeErrorGif = () => {
      setTimeout(() => {
        const imgs = document.querySelectorAll("img");
        const source = "https://servedbyadbutler.com/error/blank.gif";
        if (imgs && imgs.length > 0) {
          Array.prototype.forEach.call(imgs, (img) => {
            if (img.src === source) {
              img.remove();
            }
          });
        }
      }, 2000);
    };

    const resizeIframes = () => {
      if (findIframes) {
        const adButlerInterval = setInterval(() => {
          Array.prototype.forEach.call(findIframes, (element) => {
            if (element.id.includes("placement")) {
              try {
                const iframeBody = element?.contentWindow?.document?.body;

                // gets the rates offer class element
                const ratesOffer = iframeBody.childNodes[0].querySelector(
                  ".rates-offer"
                );

                let scrollHeight = iframeBody?.scrollHeight + 40;

                // sets the height of the iframe to 0 if the rates-offer element is set to display: none
                if (ratesOffer && ratesOffer !== null) {
                  const compStyles =
                    typeof window !== `undefined` &&
                    window.getComputedStyle(ratesOffer);

                  if (compStyles && compStyles.display === "none") {
                    scrollHeight = iframeBody?.scrollHeight;
                    element.style.height = `${scrollHeight}px`; // should equal 0 every time but not hardcoding it just inc
                  }
                }

                if (
                  scrollHeight &&
                  `${scrollHeight}px` !== element.style.height
                ) {
                  element.style.height = `${scrollHeight}px`;
                }
              } catch (error) {
                console.log(error);
              }
            }
          });
        }, 1000);
        setTimeout(() => {
          clearInterval(adButlerInterval);
        }, 10000);
      }
    };

    (async () => {
      const adButlerScriptArray = await adButlerIds.map((id, index) => {
        return `<!-- ${index} [asyncbeta] -->
        <script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
        <div class="plc${id}"></div>
        <script type="text/javascript">
        var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
        var abkw = window.abkw || '';
        var plc${id} = window.plc${id} || 0;
        (function(){
        var divs = document.querySelectorAll(".plc${id}:not([id])");
        var div = divs[divs.length-1];
        div.id = "placement_${id}_"+plc${id};
        AdButler.ads.push({handler: function(opt){ AdButler.register(169214, ${id}, [730,300], 'placement_${id}_'+opt.place, opt); }, opt: { place: plc${id}++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
        })();
        </script>`;
      });

      const adString = await adButlerScriptArray.join("");
      const replaceHttp = await adString.replace(/http:\/\//gi, "https://");

      await setHtml(replaceHttp);
      await resizeIframes();
      await removeErrorGif();
    })();
  }, [adButlerIds]);

  return (
    <div className="container showBlocks" id="adButlerShowAll">
      <div id="adbutler">
        <AdButlerContainer html={html} />
      </div>
    </div>
  );
};

export default AdButler;
