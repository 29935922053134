import React, { useState, useEffect } from "react";
import Adbutler from "../components/Adbutler";
import adbutlerZones from "../data/adbutlerZones";

const Thankyou = (props) => {
  const [adIds, setAdIds] = useState([]);

  useEffect(() => {
    // filters out the key from props.location.state
    const { key, ...filteredData } = props.location.state;

    // gets the adbutler zones based on the vertical value
    const adObject = adbutlerZones(filteredData.vertical);
    // stores the values to sessionStorage for use in the adbutler zone logic
    sessionStorage.setItem("formData", JSON.stringify(filteredData));
    setAdIds(adObject);
  }, [props]);
  return <Adbutler adButlerIds={adIds} />;
};

export default Thankyou;
